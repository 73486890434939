import styled from 'styled-components';
export const OuterStyle = styled.div`
    position: relative;
    width: 100%;
    top: 0px;

    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const InnerStyle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;

    // max-width: 1920px;
`;
