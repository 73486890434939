import styled from 'styled-components';
import AboutBackgroundPng from '../../../assets/images/AboutImg.jpg';

export const AboutPageContainer = styled.div`
    width: 100%;
`;
export const AboutDesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 70px;
    padding-bottom: 70px;
    word-break: auto-phrase;
`;

export const BackgroundContainer = styled.div`
    height: 700px;
    background-image: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url(${AboutBackgroundPng});

    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
