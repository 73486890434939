import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import About from './components/pages/About';
import Analysis from './components/pages/Analysis';
import AnalysisResult from './components/pages/AnalysisResult';
import AuthLayout from './components/pages/AuthLayout';
import BasicLayout from './components/pages/BasicLayout';
import EmailFinder from './components/pages/EmailFinder';
import Login from './components/pages/Login';
import Main from './components/pages/Main';
import NAuthLayout from './components/pages/NAuthLayout';
import PasswordFinder from './components/pages/PasswordFinder';

import Service from './components/pages/Service';
import SignUp from './components/pages/SignUp';
import Tech from './components/pages/Tech';

import './assets/styles/global.css';
import './assets/styles/responsive.css';
import Portfolio from './components/pages/Portfolio';
import PortfolioResult from './components/pages/PortfolioResult';
import PortfoliosHistory from './components/pages/PortfoliosHistory';
const router = createBrowserRouter([
    {
        path: '/',
        element: <BasicLayout />,
        children: [
            {
                path: '/',
                element: <Main />,
            },
            {
                path: '/about',
                element: <About />,
            },
            {
                path: '/service',
                element: <Service />,
            },
            {
                path: '/tech',
                element: <Tech />,
            },
            {
                path: '/forgot-email',
                element: <EmailFinder />,
            },
            {
                path: '/forgot-password',
                element: <PasswordFinder />,
            },
        ],
    },
    {
        path: '/',
        element: <NAuthLayout />,
        children: [
            { path: 'login', element: <Login /> },
            { path: 'signup', element: <SignUp /> },
        ],
    },
    {
        path: '/auth',
        element: <AuthLayout />,
        children: [
            {
                path: 'portfolios',
                element: <PortfoliosHistory />,
            },
            {
                path: 'portfolios/new',
                element: <Portfolio />,
            },
            {
                path: 'portfolios/result',
                element: <PortfolioResult />,
            },
            {
                path: 'analysis',
                element: <Analysis />,
            },
            {
                path: 'analysis/result',
                element: <AnalysisResult />,
            },
        ],
    },
]);
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient();

root.render(
    <QueryClientProvider client={queryClient}>
        <RecoilRoot>
            <RouterProvider router={router} />
        </RecoilRoot>
        <ReactQueryDevtools initialIsOpen={true} />
    </QueryClientProvider>,
);
