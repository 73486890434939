import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useRefresh } from '../../../hooks/useAuth';
import { accessTokenAtom } from '../../../recoil/accesToken';
import Footer from '../../molecules/Footer';
import Navigation from '../../molecules/Navigation';
import { navLists } from '../BasicLayout/BasicLayout';
import { InnerStyle, OuterStyle } from './style';

const AuthLayout = () => {
    const accessToken = useRecoilValue(accessTokenAtom);
    const mutation = useRefresh();
    useEffect(() => {
        if (!accessToken) mutation.mutate();
    }, []);

    return (
        <>
            <Navigation navList={navLists} />
            <OuterStyle>
                <InnerStyle>
                    <Outlet />
                </InnerStyle>
            </OuterStyle>
            <Footer />
        </>
    );
};

export default AuthLayout;
