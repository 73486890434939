import React from 'react';
import { MainImgDetailStyle, MainImgTitleStyle } from '../../atoms/Text/Text';
import { MainCardContainer, MainImgDetailWrapper } from './style';

interface MainCardProps {
    imgSrc: string;
    title: string;
    detail: string;
}

const MainCard = ({ imgSrc, title, detail }: MainCardProps) => {
    const formattedDetail = detail.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));
    const formattedTitle = title.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));
    return (
        <MainCardContainer>

            <img src={imgSrc} alt="mainFirstIcon" style={{ width: '30%', height: 'auto', maxWidth: '320px', minWidth: '150px', maxHeight: '200px' }} />
            <MainImgTitleStyle>{formattedTitle}</MainImgTitleStyle>
            <MainImgDetailWrapper>
                <MainImgDetailStyle fontWeight="300">{formattedDetail}</MainImgDetailStyle>
            </MainImgDetailWrapper>

        </MainCardContainer>
    );
};

export default MainCard;
