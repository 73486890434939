import styled from 'styled-components';

export const TechPageContainer = styled.div`
    width: 100%;
`;

export const TechMainContainer = styled.div`
    max-width: 1920px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

    padding-top: 30px;

`;

export const TechMainHeadContainer = styled.div`
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    gap: 20px;
`;

export const TechMainTailContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 30px;

    @media (max-width: 1023px) {
        flex-direction: column;
    }
`;

export const CircleStyle = styled.div`
    padding: 20px;
    box-sizing: border-box;
    width: 20vw;
    max-width: 380px;
    min-width: 150px;
    height: 20vw;
    min-height: 150px;
    max-height: 380px;
    border-radius: 50%;
    background-color: #f2f2f2;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-size: 1.6rem;
    text-align: center;

    @media (max-width: 1023px) {
        width: 40vw;
        height: 40vw;
    }
`;

export const TechTableContainer = styled.ul`

    display: flex;
    flex-direction: row;
    margin: 20px auto;
    align-items: center;
    justify-content: center;

`;

export const TechTableRow = styled.li`
    width: 80%;
    @media (max-width: 767px) {
        width: 100%;
    }
    list-style: none;
    display: flex;
    
    align-items: center;
    border-bottom: 1px solid #ccc;
    height: 85px;
    justify-content: space-between;
    text-align: center;
`;

export const TechTableCell = styled.span`
    font-size: 2.2rem;
    word-break: auto-phrase;
    width: 70px;
`;

export const TechTableBody = styled.span`
    font-size: 2.2rem;
    word-break: auto-phrase;
    flex: 1 1;
    overflow: hidden;
    padding: 0px 60px;
    @media (max-width: 767px) {
    padding: 0px 10px;
}
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const TechTableStateStyle = styled.span`
    font-size: 2rem;
    font-weight: 300;
`;