import { Dispatch, SetStateAction, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { accessTokenAtom } from '../../../recoil/accesToken';
import { navigationListType } from '../../../types/Navigation';
import { MobileMenuContainer, MobileMenuList, NavItem, NestedItem, NestedItemsContainer, Overlay } from './style';

interface IMobileMenu {
    navList: navigationListType[];
    isOpen: boolean;
    onMenuItemClick: () => void;
    openModal: () => void;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const MobileMenu = ({ navList, setIsOpen, isOpen, onMenuItemClick, openModal }: IMobileMenu) => {
    const [clickedItems, setClickedItems] = useState<boolean[]>(new Array(navList.length).fill(false));
    const isAuthenticated = useRecoilValue(accessTokenAtom);
    const toggleItemClick = (index: number) => {
        const updatedClickedItems = [...clickedItems];
        updatedClickedItems[index] = !updatedClickedItems[index];
        setClickedItems(updatedClickedItems);
    };

    return (
        <MobileMenuContainer isOpen={isOpen} className="layout-padding">
            <MobileMenuList>
                {navList.map((mainNavigation: navigationListType, idx) => {
                    const isMainItemClicked = clickedItems[idx];
                    if (!mainNavigation.nested) {
                        return (
                            <NavItem key={idx}>
                                <NavLink
                                    end
                                    to={'/' + mainNavigation.link}
                                    className={({ isActive, isPending }) => {
                                        return isActive ? 'active' : isPending ? 'pending' : '';
                                    }}
                                    onClick={() => onMenuItemClick()}
                                >
                                    {mainNavigation.name}
                                </NavLink>
                            </NavItem>
                        );
                    } else {
                        return (
                            <NavItem
                                key={idx}
                                onClick={() => {
                                    toggleItemClick(idx);
                                }}
                            >
                                <Link to={'#'}>{mainNavigation.name}</Link>
                                {isMainItemClicked && (
                                    <NestedItemsContainer>
                                        {mainNavigation.nested.map(
                                            (nestedItem) =>
                                                nestedItem.link !== '#' && (
                                                    <NestedItem key={nestedItem.name}>
                                                        <NavLink
                                                            end
                                                            to={'/' + nestedItem.link}
                                                            className={({ isActive, isPending }) => {
                                                                return isActive ? 'active' : isPending ? 'pending' : '';
                                                            }}
                                                            onClick={() => onMenuItemClick()}
                                                        >
                                                            {nestedItem.name}
                                                        </NavLink>
                                                    </NestedItem>
                                                ),
                                        )}
                                    </NestedItemsContainer>
                                )}
                            </NavItem>
                        );
                    }
                })}
                {isAuthenticated === '' ? (
                    <NavItem key={'login'}>
                        <NavLink
                            end
                            to="/login"
                            className={({ isActive, isPending }) => {
                                return isActive ? 'active' : isPending ? 'pending' : '';
                            }}
                        >
                            로그인
                        </NavLink>
                    </NavItem>
                ) : (
                    <NavItem key={'logout'} onClick={openModal}>
                        <a href="#">로그아웃</a>
                    </NavItem>
                )}
            </MobileMenuList>
            <Overlay
                isOpen={isOpen}
                onClick={() => {
                    setIsOpen(false);
                }}
            />
        </MobileMenuContainer>
    );
};

export default MobileMenu;
