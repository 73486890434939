import styled from 'styled-components';

export const SelectboxContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 5px 10px;
    cursor: pointer;
    width: 200px;
    height: 40px;

    border-radius: 5px;
    border: 1px solid #fff;
    background: #f7f7f7;
`;
export const SelectboxButton = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 2rem;
`;

export const Dropdown = styled.ul<{ isOpen: boolean }>`
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    width: 100%;
    box-sizing: border-box;
`;

export const Option = styled.li`
    padding: 8px;
    cursor: pointer;

    &:hover {
        background-color: #f0f0f0;
    }
`;
