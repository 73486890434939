import styled from 'styled-components';

export const LoginPageContainer = styled.div`
    width: 100%;
`;

export const LoginFormContainer = styled.div`
    height: 650px;
    padding: 40px 28%;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;

    @media (max-width: 767px) {
        padding: 20px 0;
    }
`;

export const LoginFormHeader = styled.div`
    width: 100%;
    text-align: center;
`;

export const LoginForm = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
`;

export const InputStyle = styled.input`
    width: 100%;
    border: none;
    border-bottom: 5px solid #f2f2f2;
    outline: none;
    padding: 8px 0px;

    font-size: 2rem;

    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px #fff inset;
        -webkit-text-fill-color: #000;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
    }
`;

export const LoginButton = styled.button<{ flag: boolean }>`
    width: 100%;
    background-color: ${(props) => (props.flag ? '#474747' : '#f2f2f2')};
    color: ${(props) => (props.flag ? '#ffffff' : '#aaaaaa')};
    font-size: 2.2rem;
    text-align: center;
    padding: 20px 0px;
    border: none;

    cursor: pointer;
`;

export const LoginSubTextField = styled.div`
    word-break: keep-all;
    font-size: 2rem;
    text-align: center;
`;
