import TechFirstImg from '../../../assets/images/MainFirstImg.png';
import TechSecondImg from '../../../assets/images/MainSecondImg.png';
import TechThirdImg from '../../../assets/images/MainThirdImg.png';
import { MainImgTitleStyle } from '../../atoms/Text/Text';
import SubJumbo from '../../molecules/SubJumbo';
import TechCard from '../../molecules/TechCard';
import { TechMainContainer, TechPageContainer, TechTableBody, TechTableCell, TechTableContainer, TechTableRow, TechTableStateStyle } from './style';

const Tech = () => {
    return (
        <TechPageContainer>
            <SubJumbo title="관악연구소만의 테크/특허" subtitle={['기술과 서비스', ' > 테크 / 특허']} />
            <TechMainContainer className="layout-padding">
                <MainImgTitleStyle fontWeight="800">1. 자체 구현 알고리즘의 3가지 특징</MainImgTitleStyle>
            </TechMainContainer>
            <TechCard
                imgSrc={TechFirstImg}
                title={'정확한 투자성향 파악'}
                detail={
                    '투자자의 투자 니즈를 정확하게 파악하여 제안합니다. \n기존 금융사 등에서 진행되는 단순 설문으로 포착하지 못하는 투자자의 투자 습관, 성향을 파악할 수 있도록 투자성향 분석 프로그램을 활용하여 정확한 개인의 투자성향을 파악합니다.'
                }
            />
            <TechCard
                imgSrc={TechSecondImg}
                title={'자체개발 엔진을 활용한\n개인 맞춤형 포트폴리오 대상 선정'}
                detail={
                    '수 많은 종목들 중, 재무정보, 가격정보, 기타 정적인 정보들을 활용해 AI기반의 예측엔진을 통해 미래의 기업가치를 예측, 성장할 것으로 판단되는 기업들을 1차적으로 선별한 후 투자자 개인성향을 더하여 가장 적합한 포트폴리오를 제공합니다.\n\n포트폴리오 대상 후보군은 기업들의 재무정보 업데이트 및 시장 상황의 변화에 따라 정기/수시적으로 리밸런싱 됩니다.'
                }
            />
            <TechCard
                imgSrc={TechThirdImg}
                title={'저위험 기반 맞춤 포트폴리오 추천'}
                detail={'선정된 포트폴리오 후보군을 대상으로 자체 개발 포트폴리오 엔진을 활용하여 대담하면서도 안정적으로 수익을 창출할 수 있는 포트폴리오를 제시합니다.'}
            />
            <TechMainContainer className="layout-padding">
                <MainImgTitleStyle fontWeight="800">2. 특허</MainImgTitleStyle>
            </TechMainContainer>
            {/* 특허제목 :  */}
            <TechTableContainer className='layout-padding'>
                <TechTableRow >
                    <TechTableCell>01</TechTableCell>
                    <TechTableBody>최적 투자 비율 결정 장치 및 방법 <br/><TechTableStateStyle>Device and Method for Determining Optimal Investment Ratio</TechTableStateStyle></TechTableBody>
                    <TechTableStateStyle>출원중</TechTableStateStyle>
                </TechTableRow>
            </TechTableContainer>
        </TechPageContainer>
    );
};

export default Tech;
