import styled from 'styled-components';
import BackgroundPng from '../../../assets/images/backgroundPng.png';
export const MainJumboContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100vh;

    background-image: url(${BackgroundPng});

    background-position: center;
    background-size: cover;
`;

export const MainJumboDescription = styled.div`
    color: #ffffff;
    text-align: center;
`;
