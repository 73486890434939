import styled from 'styled-components';
import MainImg from '../../../assets/images/MainConceptImg.jpg';


export const MainPageContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    background-color: #f9f9f9;
`;

export const MainJumboContainer = styled.div`
    position: relative;
    width: 100%;
    height: 1000px;

    background-image: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ),url(${MainImg});
    background-position: center;
    background-size: cover;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
`;

export const MainJumboDescription = styled.div`
    width: 100%;
    color: #ffffff;
    text-align: center;
`;

export const MainFooter = styled.div`
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #151515;

    width: 100%;
    height: 60px;
    z-index: 2;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const MainCardWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content:center;
    height: 1000px;
    background-color: #fff;

    @media (max-width: 767px) {
        flex-direction: column;
    }
    
`;