import { AboutDescStyle, MainImgTitleStyle } from '../../atoms/Text/Text';
import { CellSpanStyle } from '../../molecules/Footer/style';
// import { CellSpanStyle } from '../../molecules/Footer/style';
import SubJumbo from '../../molecules/SubJumbo';
import { AboutDesContainer, AboutPageContainer, BackgroundContainer } from './style';

const About = () => {
    return (
        <AboutPageContainer>
            <SubJumbo subtitle={['회사 소개']} title="관악연구소"></SubJumbo>
            <BackgroundContainer>
                <AboutDesContainer className="layout-padding">
                    <MainImgTitleStyle fontColor="#fff">주식회사 관악연구소</MainImgTitleStyle>
                    <AboutDescStyle fontWeight="300" fontColor="#fff" lineHeight="3.5rem">
                        관악연구소는 혁신적인 금융서비스를 제공하는 기업으로서,
                        <br /> 안정적이면서도 과감한 투자 경험을 할 수 있는 금융자산관리 솔루션을 서비스하고 있습니다.
                        <br />
                        <br />
                        통계적으로 검증된 최신 이론과 예측력 높은 AI 기술을 기반으로 한 자체 개발 엔진을 통해
                        <br />
                        개인 맞춤형 투자 서비스를 제공하여 안정적인 투자자산관리를 가능하게 하며, 나아가 건전한 투자문화 형성에 이바지하고자 합니다.
                    </AboutDescStyle>
                    <AboutDescStyle fontWeight="300" fontColor="#fff" lineHeight="3.5rem">
                        <CellSpanStyle>설립 연도</CellSpanStyle>| &nbsp;2023년 2월 <br />
                        <CellSpanStyle>본사 위치</CellSpanStyle>| &nbsp;서울 광진구 <br />
                        <CellSpanStyle>주요 서비스</CellSpanStyle>| &nbsp;투자자산관리 솔루션 <br />
                        <CellSpanStyle>주요 기술</CellSpanStyle>| &nbsp;자체 개발 엔진(AI 및 통계 계산학 기반)
                    </AboutDescStyle>
                </AboutDesContainer>
            </BackgroundContainer>
            
        </AboutPageContainer>
    );
};

export default About;
