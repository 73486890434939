/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';

import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { accessTokenAtom } from '../recoil/accesToken';
import { IKaKaoValidationUser, ILoginUserForm, ISignUpUserAPI, UserInfoQuery } from '../types/User';
import { getFetcher, postFetcher } from '../utils/api';
import { bearerString } from '../utils/token';

const postSignUp = (data: ISignUpUserAPI) => {
    const signupUser = {
        email: data.email,
        password: data.password,
        full_name: data.username,
        nick_name: data.nickname,
        phone_num: data.phoneNumber,
        age: data.age,
        gender: data.sex,
    };
    console.log(signupUser);
    return postFetcher('/account/signup/', signupUser);
};

const useSignUp = () => {
    const navigate = useNavigate();
    return useMutation((data: ISignUpUserAPI) => postSignUp(data), {
        onSuccess: () => {
            alert('회원가입이 완료 되었습니다!');
            navigate('/login');
        },
        onError: (error: AxiosError<any>) => {
            if (error.response?.status === 400) {
                const detail = error.response.data.detail;
                let result = '';
                Object.keys(detail).map((item) => {
                    result = result + ' ' + item + detail[item] + '\n';
                });
                alert(result);
            }
        },
    });
};

const postLogin = (data: ILoginUserForm) => {
    return postFetcher('/account/login/', data);
};

const useLogin = () => {
    const setAccessToken = useSetRecoilState(accessTokenAtom);
    const navigate = useNavigate();
    return useMutation((data: ILoginUserForm) => postLogin(data), {
        onSuccess: (data) => {
            console.log(data.detail.access_token);
            setAccessToken(data.detail.access_token);
            navigate('/auth/portfolios');
        },
        onError: (error: AxiosError) => {
            console.log(error.response?.data);
            if (error.response?.status === 400) {
                alert('아이디 혹은 비밀번호가 틀렸습니다');
            }
        },
    });
};

const postLogout = (accessToken: string | null) => {
    return postFetcher('/account/logout/', {}, { Authorization: bearerString(accessToken) });
};

const useLogout = () => {
    const navigate = useNavigate();
    const [accessToken, setAccessToken] = useRecoilState(accessTokenAtom);
    const refreshMutation = useRefresh();
    return useMutation(() => postLogout(accessToken), {
        onSuccess: () => {
            alert('로그아웃 되었습니다.');
            setAccessToken('');
            navigate('/');
        },
        onError: async (error: AxiosError) => {
            if (error.response?.status === 401) {
                const res = await refreshMutation.mutateAsync();
                console.log(res.detail.access_token);
                await postLogout(res.detail.access_token);
                alert('로그아웃되었습니다.');
                setAccessToken('');
            }
        },
    });
};

const postRefresh = () => {
    return postFetcher('/account/refresh/');
};

const useAuthCheck = () => {
    const setAccessToken = useSetRecoilState(accessTokenAtom);
    return useMutation(postRefresh, {
        onSuccess: (data) => {
            console.log(data);
            setAccessToken(data.detail.access_token);
        },
        onError: () => {
            console.log('로그인한적없는 유저 혹은 로그아웃을 진행한 유저');
        },
    });
};

const useRefresh = () => {
    // accessToken을 재발급하는 로직
    // accessToken이 없을때 혹은 만료되었을 때 실행
    const setAccessToken = useSetRecoilState(accessTokenAtom);
    const navigate = useNavigate();
    return useMutation(postRefresh, {
        onSuccess: (data) => {
            console.log(data);
            setAccessToken(data.detail.access_token);
        },
        onError: (err: AxiosError) => {
            if (err.response?.status === 401) {
                navigate('/login', { replace: true });
            }
            navigate('/login');
            console.log('refresh error : ', err);
        },
    });
};

const postKaKaoValidation = (data: IKaKaoValidationUser) => {
    const reqData = { full_name: data.fullName, phone_number: data.phoneNumber, birthday: data.birthday };
    return postFetcher('/cert/request/', reqData);
};

const useKaKaoValidation = () => {
    return useMutation((data: IKaKaoValidationUser) => postKaKaoValidation(data), {
        onSuccess: (data) => {
            console.log(data);
        },
        onError: (error: AxiosError) => {
            if (error.response?.status === 400) {
                alert('필드가 비어있습니다.');
            }
            if (error.response?.status === 501) {
                alert('입력한 수신자 정보와 카카오톡에 등록된 정보가 일치하지 않습니다.');
            }
        },
    });
};

const postVerificationDone = (certId: string) => {
    return postFetcher('/cert/check/', { certId: certId });
};

const useVerificationDone = () => {
    return useMutation((certId: string) => postVerificationDone(certId), {
        onSuccess: (data) => {
            alert(data.detail.data);
        },
        onError: (error: AxiosError) => {
            console.log(error);
        },
    });
};

const getUserInfo = (accessToken: string|null) => {
    return getFetcher('/account/info/',{ Authorization: bearerString(accessToken)});
};

const useUserInfo = () => {
    const refreshMutation = useRefresh();
    const accessToken = useRecoilValue(accessTokenAtom);
    return useQuery<UserInfoQuery>(['userInfo', accessToken], () => getUserInfo(accessToken), {
        retry: 0,
        onSuccess: (data) => {
            console.log(data);
            return data;
        },
        onError: async (error: any) => {
            if (error.response?.status === 401) {
                const res = await refreshMutation.mutateAsync();
                console.log(res.detail.access_token);
                await getUserInfo(res.detail.access_token);
            }
        },
        retryDelay: 1000,
        refetchOnWindowFocus: false,
    });
};

export { useAuthCheck, useKaKaoValidation, useLogin, useLogout, useRefresh, useSignUp, useUserInfo, useVerificationDone };

