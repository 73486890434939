import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
`;

export const TableContainer = styled.table`
    width: 100%;
    border: 1px solid #ccc;
    border-collapse: collapse; /* 테두리 겹치도록 설정 */
    border-spacing: 0; /* 간격 제거 */
`;

export const DescriptionContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const TableTitle = styled.div`
    font-weight: 600;
    font-size: 2rem;
`;
export const ButtonBox = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;
export const TableButton = styled.button``;

export const TableSubtitle = styled.div`
    font-weight: 400;
    font-size: 1.5rem;
    color: #4e4e4e;
`;

export const TableHeader = styled.tr`
    background: #f7f7f7;
`;

export const TableRow = styled.tr``;

export const TableCell = styled.td`
    font-size: 1.5rem;
    padding: 10px;
    word-break: auto-phrase;
    max-width: 100px;
    min-width: 40px;
    text-align: center;
    border: 1px solid #bbb;
`;
