import { useLocation } from 'react-router-dom';
import LogoGreySVG from '../../../assets/images/logo_grey.svg';
import { DefaultStyle } from '../../atoms/Text/Text';
import { LogoContainer } from './style';
import { CellSpanStyle, FooterContainer, FooterDescriptionWrapper, FooterHeaderRow } from './style';
import { useEffect, useState } from 'react';

const Footer = () => {
    const location = useLocation();
    const [footerHeight, setFooterHeight] = useState(0);
    useEffect(()=>{
        if (location.pathname === "/") {
            console.log("여기");
            setFooterHeight(60);
        }
        else{
            setFooterHeight(0);
        }

    },[location]);
    return (
        <FooterContainer footerHeight={footerHeight}>
            <FooterHeaderRow>
            <LogoContainer>
                <img src={LogoGreySVG} alt="logo" style={{width: "100%"}}/>
            </LogoContainer>
            </FooterHeaderRow>
            <FooterDescriptionWrapper>
                <DefaultStyle>주식회사 관악연구소</DefaultStyle>
                <DefaultStyle>서울시 광진구 구의강변로 46, 401호</DefaultStyle>
                <br/>
                <DefaultStyle><CellSpanStyle>대표이사</CellSpanStyle>|&nbsp;&nbsp;승 현 찬</DefaultStyle>
                <DefaultStyle><CellSpanStyle>사업자등록번호</CellSpanStyle>|&nbsp;&nbsp;728-87-02830</DefaultStyle>
                <DefaultStyle><CellSpanStyle>문의</CellSpanStyle>|&nbsp;&nbsp;gwanaklab@gmail.com</DefaultStyle>
                <DefaultStyle><CellSpanStyle>연락처</CellSpanStyle>|&nbsp;&nbsp;070-4101-6132</DefaultStyle>
                <br/>
                <DefaultStyle>Copyright 2023. Gwanak Lab Co., Ltd. all rights reserved.</DefaultStyle>
            </FooterDescriptionWrapper>
        </FooterContainer>
    );
};
export default Footer;
