import styled from 'styled-components';

export const NavItem = styled.li`
    margin: 10px 20px;
    font-weight: 300;
    font-size: 20px;
    text-decoration-line: none;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    a {
        color: #ffffff;
        &:hover {
            font-weight: 700;
        }
    }
`;
export const NestedItemsContainer = styled.ul`
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    width: 100%;
    list-style-type: none;
    background-color: inherit;
`;

export const NestedItem = styled.li`
    padding-right: 20px;
`;

export const MobileMenuContainer = styled.div<{ isOpen: boolean }>`
    position: fixed;
    top: 0;
    right: ${(props) => (props.isOpen ? '0' : '-100%')};
    width: 70%;
    height: 100%;
    background-color: #151515;
    transition: right 0.3s ease-in-out; /* right에만 transition 적용 */
    z-index: 99;
    overflow: hidden;
    opacity: 0.8;
    padding-top: 20px;

    @media (min-width: 767px) {
        display: none;
    }
`;

export const Overlay = styled.div<{ isOpen: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: ${(props) => (props.isOpen ? '100%' : '0')};
    height: 100%;
    background: rgba(0, 0, 0, 0.5); // 반투명한 배경 추가
    z-index: -1;
    transition: width 0.3s ease-in-out;
    pointer-events: ${(props) => (props.isOpen ? 'auto' : 'none')};
`;
export const MobileMenuButton = styled.button`
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 3;
    background: none;
    border: none;
    color: #ffffff;
    font-size: 24px; /* X 아이콘 크기 조정 */
    cursor: pointer;
`;
export const MobileMenuList = styled.ul`
    width: 100%;
    list-style-type: none;
    padding: 0px;
`;

export const MobileMenuItem = styled.li`
    a {
        color: #ffffff;
        text-decoration: none;
        font-size: 16px;
        display: block;
        padding: 10px 20px;
        transition: 0.3s;
        &:hover {
            font-weight: 700;
        }
    }
`;
