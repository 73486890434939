import React from 'react';
import { AboutDescStyle, MainImgTitleStyle } from '../../atoms/Text/Text';
import { TechCardContainer, TechCardHeadContainer, TechCardHorizontalBox, TechCardTailContainer, TechCardTitleContainer } from './style';
interface TechCardProps {
    imgSrc: string;
    title: string;
    detail: string;
}

const TechCard = ({ imgSrc, title, detail }: TechCardProps) => {
    const formattedtitle = title.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));
    const formattedDetail = detail.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));
    return (
        <TechCardContainer className="layout-padding">
            <TechCardHeadContainer>
                <TechCardTitleContainer>
                    <MainImgTitleStyle fontWeight="700">{formattedtitle}</MainImgTitleStyle>
                    <TechCardHorizontalBox />
                </TechCardTitleContainer>
                <img src={imgSrc} alt="mainFirstIcon" style={{ width: '20%', height: 'auto', maxWidth: '320px', minWidth: '150px' }} />
            </TechCardHeadContainer>
            <TechCardTailContainer>
                <AboutDescStyle fontWeight="300">{formattedDetail}</AboutDescStyle>
            </TechCardTailContainer>
        </TechCardContainer>
    );
};
export default TechCard;
