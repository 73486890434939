import styled from 'styled-components';

export const TechCardContainer = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 60px;

    max-width: 1920px;
    margin: 0 auto;
`;

export const TechCardHeadContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    gap: 20px;

    @media (max-width: 767px) {
        flex-direction: column-reverse;
    }
`;

export const TechCardTailContainer = styled.div`
    padding-left: 30%;
    box-sizing: border-box;
    width: 100%;

    line-height: 1.8;

    @media (max-width: 767px) {
        padding: 0px;
    }
`;

export const TechCardTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 30px;
`;

export const TechCardHorizontalBox = styled.div`
    width: 120px;
    border-bottom: 3px solid black;
    @media (max-width: 767px) {
        width: 100%;
    }
`;
