import styled from 'styled-components';
import ServiceJumboImg from '../../../assets/images/ServiceJumboImg.jpg';

export const ServicePageContainer = styled.div`
    width: 100%;
`;

export const ServiceDescContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    padding-top: 10%;
    padding-bottom: 10%;
`;

export const ServiceDesc = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    text-align: center;
`;

export const ServiceJumboContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 800px;

    background-image: url(${ServiceJumboImg});

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 800px;
        background-image: inherit;
        background-position: center;
        background-size: cover;
        filter: brightness(70%);
    }
    @media screen and (max-width: 767px) {
        height: 400px;
        &:before {
            height: 400px;
        }
    }
    
`;

export const ServiceJumboDescContainer = styled.div`
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 50px;

    color: #ffffff;
    filter: none;

    text-align: center;
`;
