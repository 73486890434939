import styled from 'styled-components';

export const MainCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0px 50px;
    @media (max-width: 767px) {
    padding: 0px;}

    width: 100%;
    height: 400px;
    background-color: #ffffff;

    text-align: center;
`;

export const MainImgDetailWrapper = styled.div`
    min-height: 100px;
    display: flex;
    align-items: center;
`;