/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useUserInfo } from '../../../hooks/useAuth';
import { usePortfolioHistory, usePortfolioInfo, usePortfolioList } from '../../../hooks/usePortfolio';
import { formatDateString, formatDateStringByPoint } from '../../../utils/date';
import { formattedModel, formattedRatio } from '../../../utils/portfolio';
import { getPropensity } from '../../../utils/propensity';
import Selectbox from '../../atoms/Selectbox';
import Table from '../../atoms/Table';
import { AnalysisTypeStyle, InputTitleStyle } from '../../atoms/Text/Text';
import SubJumbo from '../../molecules/SubJumbo';
import {
    AnalysisButton,
    AnalysisTypeContainer,
    AnalysisTypeSection,
    PorfolioListContainer,
    PortfoiloHistoryContainer,
    PortfolioContainer,
    PortfolioDescription,
    PortfolioDetailContainer,
    PortfolioHistorySelectContainer,
    PortfolioHistoryTitle,
    PortfolioInfoContainer,
    TableContainer,
} from './style';
import HistoryTable from '../../atoms/HistoryTable/HistoryTable';

const PortfoliosHistory = () => {
    interface StockValues {
        [date: string]: number;
    }
    interface CompanyStockData {
        [companyName: string]: StockValues;
    }
    const [standardDates, setStandardDates] = useState<string[]>([]);
    const [selectedDateIndex, setSelectedDateIndex] = useState(0);

    const [rebalancingColumnsData, setRebalancingColumnData] = useState<string[][]>([[]]);
    const { data: userInfo = { detail: { propensity: null } } } = useUserInfo();
    const { data: portfolioList = { detail: [] } } = usePortfolioList();
    const [portfolioListTableData, setPortfolioTableData] = useState<string[][]>([]);
    const [portfolioInfoTableData, setPortfolioInfoTableData] = useState<string[][]>([]);
    const [selectedOption, setSelectedOption] = useState({ id: '', name: '' });
    const { data: portfolioInfo = { detail: { portfolio: { total_money: 0, stock_list: [], created_at: '', id: '', title: '' } } } } = usePortfolioInfo(selectedOption.id);
    const tableColumns = ['포트폴리오 제목', '포트폴리오 모델 및 유형', '1주', '1개월', '3개월', '6개월', '1년', '표준편차', '최대 손실률','샤프지수', '운용날짜'];
    const portfolioInfoColumns = ['주식 종목', '포트폴리오 비율'];
    const { data: historyData, error, isLoading } = usePortfolioHistory(selectedOption.id);
    const handleSelectChange = ({ id, name }: { id?: string; name?: string }) => {
        setSelectedOption({ id: id ? id : '', name: name ? name : '' });
    };

    useEffect(() => {
        if (portfolioList.detail.length === 0) return;
        const portfolioListResult = portfolioList.detail;
        const arr = portfolioListResult.map((item) => {
            return [item.title, `${formattedModel(item.model)} ${getPropensity(item.propensity)?.name}`, '', '', '', '', '', '','', '', formatDateStringByPoint(new Date(item.created_at))];
        });
        setPortfolioTableData(arr);
    }, [portfolioList]);

    useEffect(() => {
        if (portfolioInfo.detail.portfolio.stock_list.length === 0) return;
        const filteredData = portfolioInfo.detail.portfolio.stock_list.map((item) => (item.ratio.toFixed(3) > 0.0 ? item : null)).filter((item) => item !== null);
        const sortedData = filteredData.sort((a, b) => b.ratio - a.ratio).map((item) => [item.stock_name, formattedRatio(item.ratio), item.stock_code]);
        const sum = filteredData.map((item) => {
            return item.ratio;
        });
        const result = formattedRatio(1 - sum.reduce((a, b) => a + b, 0));
        sortedData.push(['예수금', result]);
        setPortfolioInfoTableData(sortedData);
    }, [portfolioInfo]);

    useEffect(() => {
        if (isLoading) return;
        else {
            const data = historyData?.detail?.portfolio_history?.stock_list || [];
            console.log(data);
            data.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

            // 여기만 바뀌면 된다 아님?
            const uniqueDates = Array.from(new Set(data.map((item) => item.created_at.split('T')[0])));
            setStandardDates(uniqueDates);

            // const filteredData = data.filter((item) => uniqueDates.includes(item.created_at.split('T')[0]));
            // const resultsMap: Record<string, { [date: string]: number }> = {};
            // filteredData.forEach((item) => {
            //     const dateKey = item.created_at.split('T')[0];
            //     // if (uniqueDates.includes(dateKey)) {
            //     if (!resultsMap[item.stock_name]) {
            //         resultsMap[item.stock_name] = {};
            //         uniqueDates.map((asdf) => (resultsMap[item.stock_name][asdf] = 0));
            //     }
            //     // 날짜별로 ratio 값 저장
            //     if (uniqueDates.includes(dateKey)) {
            //         resultsMap[item.stock_name][dateKey] = item.ratio;
            //     }
            // });
            // const formattedData = createDataArray(resultsMap);
            // setRebalancingColumnData(formattedData);
        }
    }, [selectedOption.id, isLoading]);

    useEffect(() => {
        if (standardDates.length === 0) return;
        if (isLoading) return;
        const formatPercentage = (value: number): string => `${(value * 100).toFixed(1)}%`;
        const createDataArray = (companyStockData: CompanyStockData): string[][] => {
            return Object.entries(companyStockData).map(([companyName, values]) => {
                const dates = Object.keys(values);
                const firstValue = values[dates[0]];
                const secondValue = values[dates[1]];
                const diff = firstValue - secondValue;
                if (firstValue === 0 && secondValue === 0) return [];
                return [companyName, formatPercentage(firstValue), formatPercentage(secondValue), formatPercentage(diff) + 'p'];
            });
        };
        const data = historyData?.detail?.portfolio_history?.stock_list || [];
        console.log(data);
        console.log(standardDates);
        console.log(selectedDateIndex);
        const filteredData = data.filter((item) => standardDates.slice(selectedDateIndex, selectedDateIndex + 2).includes(item.created_at.split('T')[0]));
        console.log(filteredData);
        const resultsMap: Record<string, { [date: string]: number }> = {};
        filteredData.forEach((item) => {
            const dateKey = item.created_at.split('T')[0];
            // if (uniqueDates.includes(dateKey)) {
            if (!resultsMap[item.stock_name]) {
                resultsMap[item.stock_name] = {};
                standardDates.slice(selectedDateIndex, selectedDateIndex + 2).forEach((asdf) => (resultsMap[item.stock_name][asdf] = 0));
            }
            // 날짜별로 ratio 값 저장
            if (standardDates.slice(selectedDateIndex, selectedDateIndex + 2).includes(dateKey)) {
                resultsMap[item.stock_name][dateKey] = item.ratio;
            }
        });
        const formattedData = createDataArray(resultsMap);
        setRebalancingColumnData(formattedData);
    }, [standardDates, selectedDateIndex, isLoading]);

    const userPropensity = getPropensity(userInfo.detail.propensity);

    return (
        <PortfolioContainer>
            <SubJumbo subtitle={['포트폴리오', ' > 포트폴리오 내역']} title="포트폴리오 내역" />;
            <PortfoiloHistoryContainer className="layout-padding">
                <AnalysisTypeContainer>
                    <AnalysisTypeSection>
                        {userInfo.detail.propensity === null ? (
                            <AnalysisTypeStyle fontColor="#646464">투자성향을 파악하지 못했습니다.</AnalysisTypeStyle>
                        ) : (
                            <AnalysisTypeSection>
                                <AnalysisTypeStyle fontColor="#646464">내 투자성향은</AnalysisTypeStyle>
                                <AnalysisTypeStyle fontWeight="500">{userPropensity?.name}입니다.</AnalysisTypeStyle>
                            </AnalysisTypeSection>
                        )}
                    </AnalysisTypeSection>
                    <AnalysisButton>
                        <InputTitleStyle>
                            <Link to="/auth/analysis">{userInfo.detail.propensity === null ? '' : '재'}분석 하기</Link>
                        </InputTitleStyle>
                    </AnalysisButton>
                </AnalysisTypeContainer>
                <PorfolioListContainer>
                    <PortfolioHistoryTitle>내 포트폴리오 최근 수익률</PortfolioHistoryTitle>
                    <Table columns={tableColumns} data={portfolioListTableData} />
                </PorfolioListContainer>
                <PortfolioInfoContainer>
                    <PortfolioHistoryTitle>내 포트폴리오 세부현황</PortfolioHistoryTitle>
                    <PortfolioHistorySelectContainer>
                        {portfolioList.detail.length !== 0 && (
                            <Selectbox value={selectedOption.name} handler={handleSelectChange} data={portfolioList.detail?.map((item) => ({ id: item.id, name: item.title }))} />
                        )}
                        {portfolioInfo.detail.portfolio.id && (
                            <>
                                <PortfolioDescription>포트폴리오 명 : {portfolioInfo.detail.portfolio.title}</PortfolioDescription>
                                <PortfolioDescription>포트폴리오 생성일 : {formatDateString(portfolioInfo.detail.portfolio.created_at)}</PortfolioDescription>
                            </>
                        )}
                    </PortfolioHistorySelectContainer>
                    {portfolioInfo.detail.portfolio.id && (
                        <PortfolioDetailContainer>
                            <TableContainer>
                                <PortfolioHistoryTitle>보유 현황</PortfolioHistoryTitle>
                                <HistoryTable columns={portfolioInfoColumns} data={portfolioInfoTableData} />
                            </TableContainer>
                            <TableContainer>
                                <PortfolioHistoryTitle>최근 리밸런싱 내역</PortfolioHistoryTitle>
                                <Table
                                    columns={['종목명', standardDates[selectedDateIndex], standardDates[selectedDateIndex + 1], '증감']}
                                    data={rebalancingColumnsData}
                                    title="리밸런싱"
                                    subtitle={`${formatDateStringByPoint(new Date(standardDates[selectedDateIndex]))} 일자 기준`}
                                    selectedDateIndex={selectedDateIndex}
                                    setSelectedDateIndex={setSelectedDateIndex}
                                    standardDates={standardDates}
                                />
                            </TableContainer>
                        </PortfolioDetailContainer>
                    )}
                </PortfolioInfoContainer>
            </PortfoiloHistoryContainer>
        </PortfolioContainer>
    );
};

export default PortfoliosHistory;
