import { ModalBodyStyle, ModalButtonConatiner, ModalButtonStyle, ModalInnerStyle, ModalOuterStyle } from './style';

interface IModalProps {
    isOpen: boolean;
    onLogout: () => void;
    onClose: () => void;
}

const Modal = ({ isOpen, onLogout, onClose }: IModalProps) => {
    if (!isOpen) return null;

    return (
        <ModalOuterStyle>
            <ModalInnerStyle>
                <ModalBodyStyle>로그아웃 하시겠습니까?</ModalBodyStyle>
                <ModalButtonConatiner>
                    <ModalButtonStyle style={{ color: 'red' }} onClick={onLogout}>
                        로그아웃
                    </ModalButtonStyle>
                    <ModalButtonStyle onClick={onClose}>취소</ModalButtonStyle>
                </ModalButtonConatiner>
            </ModalInnerStyle>
        </ModalOuterStyle>
    );
};
export default Modal;
