/* eslint-disable @typescript-eslint/no-explicit-any */

import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { accessTokenAtom } from "../recoil/accesToken";
import { postFetcher } from "../utils/api";
import { bearerString } from "../utils/token";

const postAnalysisFormSubmit = (data: number , accessToken: string | null) => {
    return postFetcher('/account/propensity/', {score: data}, { Authorization: bearerString(accessToken) });
};

const useAnalysisFormSubmit = () => {
    const navigate = useNavigate();
    const accessToken = useRecoilValue(accessTokenAtom);
    return useMutation((data: number) => postAnalysisFormSubmit(data, accessToken), {
        onSuccess: () => {
            alert('제출이 완료되었습니다!');
            navigate('/auth/portfolios');
        },
        onError: (error: AxiosError<any>) => {
            console.log(error);
        },
    });
};

export { useAnalysisFormSubmit };
