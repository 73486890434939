import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useAuthCheck } from '../../../hooks/useAuth';
import { accessTokenAtom } from '../../../recoil/accesToken';
import Footer from '../../molecules/Footer';
import Navigation from '../../molecules/Navigation';
import { InnerStyle, OuterStyle } from './style';
export const navLists = [
    { name: '회사소개', link: 'about' },
    {
        name: '기술과 서비스',
        link: '#',
        nested: [
            { name: '서비스 소개', link: 'service' },
            { name: '|', link: '#'},
            { name: '테크 / 특허', link: 'tech' },
        ],
    },
    {
        name: '포트폴리오',
        link: '#',
        nested: [
            { name: '내역', link: 'auth/portfolios' },
            { name: '|', link: '#'},
            { name: '만들기', link: 'auth/portfolios/new' },
        ],
    },
];
const BasicLayout = () => {
    const accessToken = useRecoilValue(accessTokenAtom);
    const authMutation = useAuthCheck();
    useEffect(() => {
        if (!accessToken) authMutation.mutate();
    }, []);
    return (
        <>
            <Navigation navList={navLists} />
            <OuterStyle>
                <InnerStyle>
                    <Outlet />
                </InnerStyle>
            </OuterStyle>
            <Footer />
        </>
    );
};

export default BasicLayout;
