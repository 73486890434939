/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { MouseEvent, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useKaKaoValidation, useSignUp, useVerificationDone } from '../../../hooks/useAuth';
import { ISignUpUserForm } from '../../../types/User';
import Checkbox from '../../atoms/Checkbox';
import Radio from '../../atoms/Radio';
import { InputTitleStyle } from '../../atoms/Text/Text';
import SubJumbo from '../../molecules/SubJumbo';
import {
    AdditionalInfoContainer,
    ButtonMixed,
    HorizontalBar,
    InputContainer,
    InputErrorStyle,
    InputMixed,
    InputOnly,
    InputWrapper,
    InputWrapperColGap,
    InputWrapperGap,
    LoginButton,
    RadioContainer,
    SignUpForm,
    SignUpFormConatiner,
    SignupContainer,
} from './style';

const SignUp = () => {
    const [certId, setCertId] = useState('');
    const [userValid, setUserValid] = useState(false);
    const {
        register,
        handleSubmit,
        getValues,
        watch,
        formState: { errors },
    } = useForm<ISignUpUserForm>();
    const signupMutation = useSignUp();
    const kakaoMutation = useKaKaoValidation();
    const verificationMutation = useVerificationDone();

    const onSubmitHandler: SubmitHandler<ISignUpUserForm> = (data) => {
        console.log('onsubmit');
        if (userValid) {
            console.log(data);
            signupMutation.mutate(data);
        } else alert('본인 인증을 다시 진행해주세요');
    };
    const [agreementWatch, birthdayWatch, emailWatch, nicknameWatch, passwordWatch, passwordConfirmWatch, phoneNumberWatch, userNameWatch] = watch([
        'agreement',
        'birthday',
        'email',
        'nickname',
        'password',
        'passwordConfirm',
        'phoneNumber',
        'username',
    ]);
    const signupButtonFlag = agreementWatch && birthdayWatch && emailWatch && nicknameWatch && passwordConfirmWatch && passwordWatch && phoneNumberWatch && userNameWatch;

    useEffect(() => {
        if (kakaoMutation.isSuccess) {
            setCertId(kakaoMutation.data.detail.certId);
        }
    }, [kakaoMutation.isSuccess, kakaoMutation.data]);

    useEffect(() => {
        setCertId('');
        setUserValid(false);
    }, [phoneNumberWatch, birthdayWatch, userNameWatch]);

    useEffect(() => {
        if (verificationMutation.isSuccess) setUserValid(true);
    }, [verificationMutation.isSuccess]);

    const verificationButtonHandler = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const username = getValues('username');
        const phoneNumber = getValues('phoneNumber');
        const birthday = getValues('birthday');
        if (!certId) {
            kakaoMutation.mutate({
                fullName: username,
                phoneNumber: phoneNumber,
                birthday: birthday,
            });
        } else {
            verificationMutation.mutate(certId);
        }
    };

    return (
        <SignupContainer>
            <SubJumbo subtitle={['']} title="회원가입" />
            <SignUpFormConatiner className="layout-padding">
                <SignUpForm onSubmit={handleSubmit(onSubmitHandler)}>
                    <InputContainer>
                        <InputTitleStyle>이메일</InputTitleStyle>
                        <InputWrapper>
                            <InputOnly {...register('email')} name="email" type="email" placeholder="이메일 주소를 입력해주세요" />
                        </InputWrapper>
                    </InputContainer>
                    <InputContainer>
                        <InputTitleStyle>비밀번호</InputTitleStyle>
                        <InputWrapper>
                            <InputOnly
                                {...register('password', {
                                    minLength: {
                                        value: 8,
                                        message: '8글자 이상 입력해주세요.',
                                    },
                                    pattern: {
                                        value: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*?_]).{8,16}$/,
                                        message: '영문, 숫자, 특수문자가 포함되게 입력해주세요. ',
                                    },
                                    maxLength: {
                                        value: 15,
                                        message: '16글자 이내로 입력해주세요.',
                                    },
                                })}
                                name="password"
                                type="password"
                                placeholder="영문/숫자/특수문자가 포함된 8자리 이상"
                            />
                        </InputWrapper>
                        {errors.password && <InputErrorStyle>{errors.password.message}</InputErrorStyle>}
                    </InputContainer>
                    <InputContainer>
                        <InputTitleStyle>비밀번호 확인</InputTitleStyle>
                        <InputWrapper>
                            <InputOnly
                                {...register('passwordConfirm', {
                                    validate: {
                                        check: (val) => {
                                            if (getValues('password') !== val) {
                                                return '비밀번호가 일치하지 않습니다.';
                                            }
                                        },
                                    },
                                })}
                                name="passwordConfirm"
                                type="password"
                                placeholder="다시 한 번 입력해주세요"
                            />
                        </InputWrapper>
                        {errors.passwordConfirm && <InputErrorStyle>{errors.passwordConfirm.message}</InputErrorStyle>}
                    </InputContainer>
                    <InputContainer>
                        <InputTitleStyle>이름</InputTitleStyle>
                        <InputWrapper>
                            <InputOnly {...register('username', { required: true })} name="username" type="text" placeholder="예) 홍길동" />
                        </InputWrapper>
                    </InputContainer>
                    <InputContainer>
                        <InputTitleStyle>생일</InputTitleStyle>
                        <InputWrapper>
                            <InputOnly
                                {...register('birthday', {
                                    pattern: {
                                        value: /^\d{8}$/,
                                        message: '8자리로 표현해주세요 ex) 19990224',
                                    },
                                })}
                                name="birthday"
                                type="text"
                                placeholder="ex) 20010529"
                            />
                        </InputWrapper>
                        {errors.birthday && <InputErrorStyle>{errors.birthday.message}</InputErrorStyle>}
                    </InputContainer>
                    <InputContainer>
                        <InputTitleStyle>닉네임</InputTitleStyle>
                        <InputWrapper>
                            <InputOnly
                                {...register('nickname', {
                                    maxLength: {
                                        value: 8,
                                        message: '8글자 이내로 작성해주세요.',
                                    },
                                })}
                                name="nickname"
                                type="text"
                                placeholder="최대 8글자"
                            />
                        </InputWrapper>
                        {errors.nickname && <InputErrorStyle>{errors.nickname.message}</InputErrorStyle>}
                    </InputContainer>
                    <InputContainer>
                        <InputTitleStyle>휴대폰번호</InputTitleStyle>
                        <InputWrapper>
                            <InputMixed
                                {...register('phoneNumber', {
                                    pattern: {
                                        value: /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/,
                                        message: '전화번호 형식에 맞게 입력해주세요.',
                                    },
                                })}
                                name="phoneNumber"
                                type="text"
                                placeholder="숫자만 입력해주세요."
                            />
                            <ButtonMixed
                                onClick={(e) => {
                                    verificationButtonHandler(e);
                                }}
                                disabled={Boolean(!userNameWatch && !phoneNumberWatch && !birthdayWatch) || userValid}
                                flag={userValid ? false : Boolean(userNameWatch && phoneNumberWatch && birthdayWatch)}
                            >
                                {certId ? '인증완료' : '인증'}
                            </ButtonMixed>
                        </InputWrapper>
                        {userValid ? '' : certId && <InputErrorStyle>카카오톡에서 인증완료시 위의 인증완료 버튼을 눌러주세요.</InputErrorStyle>}
                        {errors.phoneNumber && <InputErrorStyle>{errors.phoneNumber.message}</InputErrorStyle>}
                    </InputContainer>

                    <AdditionalInfoContainer>
                        <InputTitleStyle>추가정보 (선택)</InputTitleStyle>
                        <HorizontalBar />
                        <RadioContainer>
                            <InputTitleStyle>성별</InputTitleStyle>
                            <InputWrapperGap>
                                <Radio value="M" label="남성" {...register('sex')} />
                                <Radio value="W" label="여성" {...register('sex')} />
                            </InputWrapperGap>
                        </RadioContainer>
                        <RadioContainer>
                            <InputTitleStyle>연령대</InputTitleStyle>
                            <InputWrapperColGap>
                                <Radio value="1" label="19세 이하" {...register('age')} />
                                <Radio value="2" label="20세 ~ 40세" {...register('age')} />
                                <Radio value="3" label="41세 ~ 50세" {...register('age')} />
                                <Radio value="4" label="51세 ~ 60세" {...register('age')} />
                                <Radio value="5" label="61세 이상" {...register('age')} />
                            </InputWrapperColGap>
                        </RadioContainer>
                    </AdditionalInfoContainer>
                    <Checkbox value="1" label="이용약관 및 개인정보수집방침에 동의합니다." {...register('agreement', { required: true })} style={{ textDecoration: 'underline' }} />

                    <LoginButton flag={Boolean(signupButtonFlag) && userValid} disabled={!(Boolean(signupButtonFlag) && userValid)}>
                        가입하기
                    </LoginButton>
                </SignUpForm>
            </SignUpFormConatiner>
        </SignupContainer>
    );
};

export default SignUp;
