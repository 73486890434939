/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserInfo } from '../../../hooks/useAuth';
import { usePortfolioInfo, usePutPortfolio } from '../../../hooks/usePortfolio';
import { IPortfolioUpdate } from '../../../types/Portfolio';
import { formattedRatio } from '../../../utils/portfolio';
import { getPropensity } from '../../../utils/propensity';
import SubJumbo from '../../molecules/SubJumbo';
import {
    CustomTableStyle,
    FormContainer,
    InputDetail,
    InputStyle,
    PortfolioDescription,
    PortfolioDetailContainer,
    PortfolioResultContainer,
    PortfolioTableContainer,
    PropensityTitle,
    ResultButton,
    TBody,
    THead,
    Td,
    Tr,
} from './style';

const PortfolioResult = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { register, handleSubmit, watch } = useForm<IPortfolioUpdate>();
    const [portfolioId, setPortfolioId] = useState<string>(location.state || '');
    const { data: userInfo = { detail: { propensity: null } } } = useUserInfo();
    const { data: portfolioInfo = { detail: { portfolio: { is_result: false, stock_list: [], created_at: '', id: '', title: '', propensity: 0, total_money: 10000000 } } } } =
        usePortfolioInfo(portfolioId);
    const portfolioMutation = usePutPortfolio();
    const userPropensity = getPropensity(userInfo.detail.propensity);

    const onSubmitHandler: SubmitHandler<IPortfolioUpdate> = (data) => {
        console.log(data);
        if (data.title.length > 20) {
            alert('포트폴리오 명은 20자를 넘을 수 없습니다.');
            return;
        }
        if (data.total_money < 5000000) {
            alert('희망 투자 금액의 최소 금액은 5,000,000원입니다.');
            return;
        }
        if (data.total_money % 500000 !== 0) {
            alert('희망 투자 금액의 최소 단위는 500,000원입니다.');
            return;
        }
        portfolioMutation.mutate({ id: portfolioId, title: data.title, total_money: data.total_money });
    };

    useEffect(() => {
        if (!location.state) {
            console.log('location : ', location);
            navigate('/auth/portfolios/new', { replace: true });
        } else {
            console.log('location : ', location.state);
            setPortfolioId(location.state);
        }
    }, []);

    return (
        <PortfolioResultContainer>
            <SubJumbo subtitle={['포트폴리오', ' > 포트폴리오 만들기']} title="포트폴리오 생성" />;<PortfolioTableContainer className="layout-padding"></PortfolioTableContainer>
            <PortfolioTableContainer className="layout-padding">
                <PropensityTitle>당신의 투자성향은 {userPropensity ? userPropensity.name : ''}입니다.</PropensityTitle>
                {portfolioInfo.detail.portfolio.is_result === false ? (
                    <PortfolioDescription>포트폴리오 생성중입니다.</PortfolioDescription>
                ) : (
                    <>
                        <PortfolioDescription>희망투자금액에 따른 {getPropensity(portfolioInfo.detail.portfolio.propensity)?.name} 포트폴리오가 다음과 같이 생성되었습니다.</PortfolioDescription>
                        <FormContainer onSubmit={handleSubmit(onSubmitHandler)}>
                            <PortfolioDetailContainer>
                                <InputDetail>포트폴리오 명 : </InputDetail>
                                <InputStyle
                                    defaultValue={portfolioInfo.detail.portfolio.title}
                                    type="text"
                                    placeholder="20자 이내로 입력해주세요"
                                    {...register('title', { required: true })}
                                ></InputStyle>
                                <InputDetail>희망투자금액 : </InputDetail>
                                <InputStyle defaultValue={5000000} type="number" placeholder="기본 단위 : 만" min="5000000" step="500000" {...register('total_money', { required: true })}></InputStyle>
                            </PortfolioDetailContainer>
                            <CustomTableStyle>
                                <colgroup>
                                    <col width={100}></col>
                                    <col width={200}></col>
                                    <col width={150}></col>
                                    <col width={150}></col>
                                </colgroup>
                                <THead>
                                    <Tr>
                                        <Td></Td>
                                        <Td>종목이름</Td>
                                        <Td>금액</Td>
                                        <Td>비중</Td>
                                    </Tr>
                                </THead>
                                <TBody>
                                    {portfolioInfo.detail.portfolio.stock_list
                                        .sort((a, b) => b.ratio - a.ratio)
                                        .map((item, id) => {
                                            if (Number(item.ratio.toFixed(3)) <= 0.0) return;
                                            return (
                                                <Tr key={id}>
                                                    <Td>{id + 1}</Td>
                                                    <Td><a href={`https://m.stock.naver.com/domestic/stock/${item.stock_code}/total`} 
                                        target="_blank"
                                        rel="noreferrer noopener">{item.stock_name}</a></Td>
                                                    <Td>{Math.floor((watch('total_money') ? watch('total_money') : 5000000) * item.ratio).toLocaleString('ko-KR')}</Td>
                                                    <Td>{formattedRatio(item.ratio)}</Td>
                                                </Tr>
                                            );
                                        })}
                                </TBody>
                            </CustomTableStyle>
                            <ResultButton type="submit">포트폴리오 생성</ResultButton>
                        </FormContainer>
                    </>
                )}
            </PortfolioTableContainer>
        </PortfolioResultContainer>
    );
};

export default PortfolioResult;
