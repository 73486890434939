import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import LogoSVG from '../../../assets/images/logo.svg';
import { useLogout } from '../../../hooks/useAuth';
import useModal from '../../../hooks/useModal';
import { accessTokenAtom } from '../../../recoil/accesToken';
import { navigationListType } from '../../../types/Navigation';
import MobileMenu from '../MobileMenu';
import Modal from '../Modal';
import { LogoContainer, MobileMenuButton, NavItem, NavItemsContainer, NavigationContainer, NestedItem, NestedItemsContainer } from './style';

type mytype = {
    navList: navigationListType[];
};

const Navigation = ({ navList }: mytype) => {
    const [hoveredItem, setHoverdItem] = useState<number | null>(null);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const isAuthenticated = useRecoilValue(accessTokenAtom);
    const { isModalOpen, openModal, closeModal } = useModal();
    const logoutMutation = useLogout();

    return (
        <>
            <NavigationContainer className="layout-padding">
                <LogoContainer>
                    <Link to="/">
                        <img src={LogoSVG} alt="logo" style={{ width: '100%' }} />
                    </Link>
                </LogoContainer>
                <NavItemsContainer>
                    {navList.map((mainNavigation: navigationListType, idx) => {
                        const isMainItemHovered = hoveredItem === idx;
                        if (!mainNavigation.nested) {
                            return (
                                <NavItem key={idx}>
                                    <NavLink
                                        end
                                        to={'/' + mainNavigation.link}
                                        className={({ isActive, isPending }) => {
                                            return isActive ? 'active' : isPending ? 'pending' : '';
                                        }}
                                    >
                                        {mainNavigation.name}
                                    </NavLink>
                                </NavItem>
                            );
                        } else {
                            return (
                                <NavItem
                                    key={idx}
                                    onMouseEnter={() => {
                                        setHoverdItem(idx);
                                    }}
                                    onMouseLeave={() => {
                                        setHoverdItem(null);
                                    }}
                                >
                                    <Link to="#">{mainNavigation.name}</Link>
                                    {isMainItemHovered && (
                                        <NestedItemsContainer>
                                            {mainNavigation.nested.map((nestedItem) => (
                                                <NestedItem key={nestedItem.name}>
                                                    {nestedItem.link === '#' ? (
                                                        <span className="disabled-link">{nestedItem.name}</span> // 클릭 이벤트가 없는 span 태그 사용
                                                    ) : (
                                                        <NavLink
                                                            end
                                                            to={'/' + nestedItem.link}
                                                            className={({ isActive, isPending }) => {
                                                                return isActive ? 'active' : isPending ? 'pending' : '';
                                                            }}
                                                        >
                                                            {nestedItem.name}
                                                        </NavLink>
                                                    )}
                                                </NestedItem>
                                            ))}
                                        </NestedItemsContainer>
                                    )}
                                </NavItem>
                            );
                        }
                    })}
                    {isAuthenticated === '' ? (
                        <NavItem key={'login'}>
                            <NavLink
                                end
                                to="login"
                                className={({ isActive, isPending }) => {
                                    return isActive ? 'active' : isPending ? 'pending' : '';
                                }}
                            >
                                로그인
                            </NavLink>
                        </NavItem>
                    ) : (
                        <NavItem key={'logout'} onClick={openModal}>
                            <a href="#">로그아웃</a>
                        </NavItem>
                    )}
                </NavItemsContainer>
                <MobileMenuButton onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </MobileMenuButton>
                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onLogout={() => {
                            logoutMutation.mutate();
                            closeModal();
                        }}
                        onClose={closeModal}
                    ></Modal>
                )}
                <MobileMenu navList={navList} setIsOpen={setIsMobileMenuOpen} isOpen={isMobileMenuOpen} onMenuItemClick={() => setIsMobileMenuOpen(false)} openModal={openModal} />
            </NavigationContainer>
        </>
    );
};
export default Navigation;
