import { SubmitHandler, useForm } from 'react-hook-form';
import { useUserInfo } from '../../../hooks/useAuth';
import { usePortfolioFormSubmit } from '../../../hooks/usePortfolio';
import { IPortfolioDetail } from '../../../types/Portfolio';
import { getPropensity, getPropensityTableData } from '../../../utils/propensity';
import Radio from '../../atoms/Radio';
import { AnalysisTypeStyle, InputTitleStyle } from '../../atoms/Text/Text';
import SubJumbo from '../../molecules/SubJumbo';
import {
    CustomTableStyle,
    FormContainer,
    ModelContainer,
    PortfolioContainer,
    PortfolioTableContainer,
    PortfolioTableTitle,
    PortfolioTendencyConatiner,
    PropensityTitle,
    ResultButton,
    TBody,
    THead,
    Td,
    Tr,
} from './style';

const Portfolio = () => {
    const { data: userInfo = { detail: { propensity: null } } } = useUserInfo();
    const userPropensity = getPropensity(userInfo.detail.propensity);
    const propensityTableData = getPropensityTableData();
    const { register, handleSubmit } = useForm<IPortfolioDetail>();
    const portfolioMutation = usePortfolioFormSubmit();

    const onSubmitHandler: SubmitHandler<IPortfolioDetail> = (data) => {
        console.log(data);
        portfolioMutation.mutate(data);
    };
    return (
        <PortfolioContainer>
            <SubJumbo subtitle={['포트폴리오', ' > 포트폴리오 만들기']} title="포트폴리오 생성" />
            {userPropensity ? (
                <PortfolioTendencyConatiner className="layout-padding">
                    <PropensityTitle>당신의 투자성향은 {userPropensity ? userPropensity.name : ''}입니다.</PropensityTitle>
                    <PortfolioTableContainer>
                        <PortfolioTableTitle>포트폴리오 종류를 선택하세요.</PortfolioTableTitle>
                        <ModelContainer>
                            <Radio
                                value={'pess'}
                                label={
                                    <InputTitleStyle fontColor="#303030" style={{ marginLeft: '27px;' }}>
                                        SAFE
                                        <br />
                                        리스크 최소화에 중점을 둔 포트폴리오
                                    </InputTitleStyle>
                                }
                                {...register('model', { required: true })}
                            />
                            <Radio
                                value={'equal'}
                                label={
                                    <InputTitleStyle fontColor="#303030" style={{ marginLeft: '27px;' }}>
                                        TOTAL
                                        <br />
                                        전통적 방식의 포트폴리오
                                    </InputTitleStyle>
                                }
                                {...register('model', { required: true })}
                            />
                        </ModelContainer>
                    </PortfolioTableContainer>

                    <PortfolioTableContainer>
                        <PortfolioTableTitle>본인에게 맞는 포트폴리오 유형을 선택하세요.</PortfolioTableTitle>
                        <PortfolioTableTitle>
                            {userPropensity ? userPropensity.name : ''}은 위험자산의 비중을 최대 {userPropensity ? (1 - userPropensity.cashRatio) * 100 : ''}%까지 설정할 수 있습니다.
                        </PortfolioTableTitle>
                        <FormContainer onSubmit={handleSubmit(onSubmitHandler)}>
                            <CustomTableStyle>
                                <colgroup>
                                    <col width={30}></col>
                                    <col width={200}></col>
                                    <col width={150}></col>
                                    <col width={150}></col>
                                </colgroup>
                                <THead>
                                    <Tr>
                                        <Td></Td>
                                        <Td></Td>
                                        <Td>국내주식(위험자산)</Td>
                                        <Td>현금(안정자산)</Td>
                                    </Tr>
                                </THead>
                                <TBody>
                                    {propensityTableData.map((item, id) => {
                                        const disabled = userPropensity ? userPropensity.typeNum < item.typeNum : true;
                                        // if (id === 1 || id === 3) return;
                                        return (
                                            <Tr key={id}>
                                                <Td>
                                                    <Radio type="plain" key={item.typeNum} value={item.typeNum.toString()} {...register('propensity', { required: true })} disabled={disabled} />
                                                </Td>
                                                <Td disabled={disabled}>{item.name}</Td>
                                                <Td disabled={disabled}>{item.stockStr}</Td>
                                                <Td disabled={disabled}>{item.cashStr}</Td>
                                            </Tr>
                                        );
                                    })}
                                </TBody>
                            </CustomTableStyle>
                            <ResultButton type="submit">포트폴리오 생성</ResultButton>
                        </FormContainer>
                    </PortfolioTableContainer>
                </PortfolioTendencyConatiner>
            ) : (
                <PortfolioTendencyConatiner className="layout-padding">
                    <AnalysisTypeStyle fontColor="#646464">투자성향을 파악하지 못했습니다.</AnalysisTypeStyle>
                </PortfolioTendencyConatiner>
            )}
        </PortfolioContainer>
    );
};

export default Portfolio;
