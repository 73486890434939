import { Main30Style, SubJumboSubTitleStyle } from '../../atoms/Text/Text';
import { SubJumboContainer, SubJumboDescription } from './style';

interface SubJumboProps {
    subtitle?: string[];
    title: string;
}

const SubJumbo = ({ subtitle, title }: SubJumboProps) => {
    return (
        <SubJumboContainer className="layout-padding">
            <SubJumboDescription>
                <SubJumboSubTitleStyle>
                    {subtitle
                        ? subtitle.map((item, key) => {
                              const length = subtitle.length;
                              if (length === 1) {
                                  return <span key={key}>{item}</span>;
                              } else {
                                  return (
                                      <span style={key == 0 ? { color: '#949494' } : {}} key={key}>
                                          {item}
                                      </span>
                                  );
                              }
                          })
                        : ''}
                </SubJumboSubTitleStyle>
                <Main30Style fontWeight="800">{title}</Main30Style>
            </SubJumboDescription>
            <SubJumboDescription></SubJumboDescription>
        </SubJumboContainer>
    );
};

export default SubJumbo;
