/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import Arrow from '../../../assets/images/arrow_svg.svg';
import { Dropdown, Option, SelectboxButton, SelectboxContainer } from './style';

interface ISelectbox {
    value: string;
    handler: ({ id, name }: { id?: string; name?: string }) => void;
    data: Array<{ id: string | undefined; name: string | undefined }>;
}

const Selectbox = ({ value, handler, data }: ISelectbox) => {
    const [isOpen, setIsOpen] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const handleSelectChange = ({ id, name }: { id: string | undefined; name: string | undefined }) => {
        if (id) {
            handler({ id: id, name: name });
        } else {
            handler({ id: '', name: '' });
        }

        setIsOpen(false);
    };
    return (
        <SelectboxContainer>
            <SelectboxButton onClick={() => setIsOpen(!isOpen)}>{value || '선택'}</SelectboxButton>
            <img src={Arrow} height={40} width={40} onClick={() => setIsOpen(!isOpen)} />
            <Dropdown isOpen={isOpen}>
                <Option key="" onClick={() => handleSelectChange({ id: '', name: '' })}>
                    선택
                </Option>
                {data.map((item) => (
                    <Option key={item.id} onClick={() => handleSelectChange({ id: item.id, name: item.name })}>
                        {item.name}
                    </Option>
                ))}
            </Dropdown>
        </SelectboxContainer>
    );
};

export default Selectbox;
