import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

// Logging
const logOnDev = (message: string) => {
    if (process.env.MODE !== 'development') {
        console.log(message);
    }
};

// error handling
const onError = async (message: string) => {
    console.log('error :', message);
    //   setAlertMessage({
    //     type: "error",
    //     message,
    //   });
    return Promise.resolve();
};

const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const { method, url } = config;
    logOnDev(`🚀 [API] ${method?.toUpperCase()} ${url} | Request`);
    if (method === 'get') {
        config.params = {
            ...config.params,
            _t: Date.now(),
        };
        config.timeout = 3000;
    }
    return config;
};

// ResponseInterceptor
const onResponse = (response: AxiosResponse): AxiosResponse => {
    const { method, url } = response.config;
    const { status } = response;
    logOnDev(`🚀 [API] ${method?.toUpperCase()} ${url} | Response ${status}`);
    return response;
};

// ErrorInterceptor
const onErrorResponse = (error: AxiosError | Error): Promise<AxiosError> => {
    if (axios.isAxiosError(error)) {
        const { message } = error;
        const { method, url } = error.config as AxiosRequestConfig;
        const { status } = error?.response as AxiosResponse;

        logOnDev(`🚨 [API] ${method?.toUpperCase()} ${url} | Error ${status} ${message}`);

        switch (status) {
            case 401: {
                onError('토큰이 만료되었습니다.');
                break;
            }
            case 403: {
                onError('권한이 없습니다.');
                break;
            }
            case 404: {
                onError('잘못된 요청입니다.');
                break;
            }
            case 500: {
                onError('서버에 문제가 발생했습니다.');
                break;
            }
            default: {
                onError('알 수 없는 오류가 발생했습니다.');
                break;
            }
        }
    } else {
        logOnDev(`🚨 [API] | Error ${error.message}`);
        onError(error.message);
    }

    return Promise.reject(error);
};

const setupInterceptors = (instance: AxiosInstance): AxiosInstance => {
    instance.interceptors.request.use(onRequest);
    instance.interceptors.response.use(onResponse, onErrorResponse);

    return instance;
};

const instance = axios.create({
    baseURL: process.env.REACT_APP_SERVER,
});
instance.defaults.withCredentials = true;

export const axiosInstance = setupInterceptors(instance);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFetcher = async (url: string, headers?: any) => {
    const response = await axiosInstance.get(url, {
        responseType: 'json',
        headers: { ...headers },
    });
    return response.data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postFetcher = async (url: string, data?: any, headers?: any) => {
    const response = await axiosInstance.post(
        url,
        { ...data },
        {
            responseType: 'json',
            headers: { ...headers, 'Content-Type': 'application/json' },
        },
    );
    return response.data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const putFetcher = async (url: string, data?: any, headers?: any) => {
    const response = await axiosInstance.put(
        url,
        { ...data },
        {
            responseType: 'json',
            headers: { ...headers, 'Content-Type': 'application/json' },
        },
    );
    return response.data;
};
