import styled from 'styled-components';
export const PortfolioContainer = styled.div`
    width: 100%;
`;

export const PortfoiloHistoryContainer = styled.div`
    width: 100%;
`;

export const AnalysisTypeContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0px;
    margin-bottom: 100px;
    border-bottom: 1px solid #d2d2d2;
`;

export const AnalysisTypeSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const AnalysisButton = styled.button`
    background-color: #0465d7;
    border-radius: 5px;
    width: 200px;
    height: 60px;
    color: #fff;
    border: 0px;
`;

export const RebalancingButton = styled.button`
    background-color: #0465d7;
    border-radius: 5px;
    width: 200px;
    height: 60px;
    color: #fff;
    border: 0px;
`;

export const PortfolioHistoryTitle = styled.div`
    font-size: 2rem;
    font-weight: 700;
`;

export const PorfolioListContainer = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 50px;
    margin-bottom: 100px;
`;

export const PortfolioInfoContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 50px;
    padding: 50px 0px;
`;

export const PortfolioHistorySelectContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    gap: 30px;
    padding: 20px 0px;
    border-bottom: 1px solid #d2d2d2;
`;

export const TableContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 50px;
`;

export const PortfolioDetailContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 80px;
`;

export const PortfolioDescription = styled.div`
    color: #4e4e4e;
    font-size: 1.5rem;
`;
