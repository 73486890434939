import styled from 'styled-components';

export const SignupContainer = styled.div`
    width: 100%;
`;

export const SignUpFormConatiner = styled.div`
    width: 100%;
`;

export const SignUpForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 50px;
    margin-bottom: 50px;
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 35%;
    min-width: 350px;
`;

export const RadioContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    max-width: 800px;
`;

export const InputErrorStyle = styled.p`
    font-size: 12px;
    color: #ff0000;
    word-wrap: break-word;
`;

export const InputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`;

export const InputWrapperGap = styled.div`
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 20px;
`;
export const InputWrapperColGap = styled.div`
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
`;



export const InputMixed = styled.input`
    border-radius: 5px 0px 0px 5px;
    border: 1px solid #eaeaea;
    padding: 10px 10px;
    font-size: 14px;
    vertical-align: middle;
    width: 100%;
    outline: none;
`;

export const ButtonMixed = styled.button<{ flag: boolean }>`
    font-size: 14px;
    width: 140px;
    border: none;
    padding: 12px 24px;
    border-radius: 0px 5px 5px 0px;
    background: ${(props) => (props.flag ? '#474747' : '#eaeaea')};
    transform: translateX(-1px);
    color: ${(props) => (props.flag ? '#fff' : '#ccc')};
`;

export const InputOnly = styled.input`
    border-radius: 5px;
    border: 1px solid #eaeaea;
    padding: 10px 10px;
    font-size: 14px;

    width: 100%;
    outline: none;
`;

export const LoginButton = styled.button<{ flag: boolean }>`
    width: 100%;
    max-width: 800px;
    background-color: ${(props) => (props.flag ? '#474747' : '#f2f2f2')};
    color: ${(props) => (props.flag ? '#ffffff' : '#aaaaaa')};
    font-size: 2.5rem;
    text-align: center;
    padding: 20px 0px;
    border: none;

    cursor: pointer;
`;
export const HorizontalBar = styled.div`
    width: 100%;
    height: 2px;
    background-color: #f9f9f9;

    max-width: 800px;
`;
export const AdditionalInfoContainer = styled.div`
    width: 35%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 50px;

    margin-top: 80px;
    margin-bottom: 80px;
`;
