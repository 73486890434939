import styled from 'styled-components';

export const NavigationContainer = styled.nav`
    box-sizing: border-box;
    position: absolute;
    z-index: 1;

    width: 100%;
    height: 70px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    user-select: none;
`;

export const LogoContainer = styled.div`
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    width: 150px;
    @media (max-width: 767px) {
        width: 100px;
    }
`;
export const NavItemsContainer = styled.ul`
    display: flex;
    flex-direction: row;
    line-height: 70px;
    list-style-type: none;
    padding: 0px;
    
    @media (max-width: 767px) {
        display: none;
    }
`;

export const NavItem = styled.li`
    margin: 0px 20px;
    font-weight: 400;
    font-size: 20px;
    text-decoration-line: none;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    a {
        color: #ffffff;
        &:hover {
            font-weight: 700;
        }
    }
`;

export const NestedItemsContainer = styled.ul`
    padding: 5px 50px;
    position: absolute;
    display: flex;
    width: max-content;
    transform: translate(-50%, 0px);
    margin-left: 50%;
    list-style-type: none;

    border-radius: 30px;
    background-color: #151515;

    li:last-child {
        padding: 0px;
    }
`;

export const NestedItem = styled.li`
    a {
        color: #ffffff;
    }
    line-height: 50px;
    padding-right: 20px;
`;

export const MobileMenuButton = styled.div`
    display: none;
    flex-direction: column;
    cursor: pointer;
    span {
        width: 25px;
        height: 3px;
        background-color: #ffffff;
        margin: 3px 0;
        border-radius: 5px;
    }

    @media (max-width: 767px) {
        display: flex;
    }
`;

export const MobileMenuContainer = styled.div<{ isOpen: boolean }>`
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 70%;
    background-color: #151515;
    transform: translateX(${(props) => (props.isOpen ? '0' : '100%')});
    transition: transform 0.3s ease-in-out;
    z-index: 2;
`;
export const MobileMenuList = styled.ul`
    list-style-type: none;
    padding: 20px 0;
`;

export const MobileMenuItem = styled.li`
    a {
        color: #ffffff;
        text-decoration: none;
        font-size: 16px;
        display: block;
        padding: 10px 20px;
        transition: 0.3s;
        &:hover {
            font-weight: 700;
        }
    }
`;
