/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { accessTokenAtom } from '../recoil/accesToken';
import { IPOrtfolioPutInfo, IPortfolioDetail, SPortfolio, SPortfolioHistory, SPortfolioList } from '../types/Portfolio';
import { getFetcher, postFetcher, putFetcher } from '../utils/api';
import { bearerString } from '../utils/token';
import { useRefresh } from './useAuth';

const postPortfolioFormSubmit = (data: IPortfolioDetail, accessToken: string | null) => {
    return postFetcher('/portfolio/', data, { Authorization: bearerString(accessToken) });
};

const usePortfolioFormSubmit = () => {
    const navigate = useNavigate();
    const accessToken = useRecoilValue(accessTokenAtom);
    return useMutation((data: IPortfolioDetail) => postPortfolioFormSubmit(data, accessToken), {
        onSuccess: (data) => {
            alert('제출완료');
            navigate('/auth/portfolios/result', { state: data?.detail?.portfolio_id });
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError: (error: AxiosError<any>) => {
            console.log(error);
        },
    });
};

const getPortfolioList = (accessToken: string | null) => {
    return getFetcher('/portfolio/', { Authorization: bearerString(accessToken) });
};

const usePortfolioList = () => {
    const accessToken = useRecoilValue(accessTokenAtom);
    const refreshMutation = useRefresh();
    return useQuery<SPortfolioList>(['portfolioList', accessToken], () => getPortfolioList(accessToken), {
        retry: 0,
        onSuccess: (data) => {
            console.log(data);
            return data;
        },
        onError: async (error: any) => {
            if (error.response?.status === 401) {
                const res = await refreshMutation.mutateAsync();
                console.log(res.detail.access_token);
                await getPortfolioList(res.detail.access_token);
            }
        },
        retryDelay: 1000,
        refetchOnWindowFocus: false,
    });
};

const getPortfolioInfo = async (portfolioId: string, accessToken: string | null) => {
    const res = await getFetcher(`/portfolio/${portfolioId}/`, { Authorization: bearerString(accessToken) });
    const data = res;
    console.log('getPortfolioInfo : ', data);
    if (res.detail.portfolio.is_result) {
        return res;
    } else {
        throw new Error('Retry');
    }
};

const usePortfolioInfo = (portfolioId: string) => {
    const accessToken = useRecoilValue(accessTokenAtom);
    const refreshMutation = useRefresh();
    return useQuery<SPortfolio>(
        ['portforlioInfo', accessToken, portfolioId],
        () => {
            if (portfolioId === '') {
                throw new Error('Invalid portfolioId');
            }

            return getPortfolioInfo(portfolioId, accessToken);
        },
        {
            retry: true,
            retryDelay: 2000,
            onSuccess: (data) => {
                console.log(data);
                return data;
            },
            onError: async (error: any) => {
                if (error.response?.status === 401) {
                    if (portfolioId === null) throw new Error('Invalid porfolidId');
                    const res = await refreshMutation.mutateAsync();
                    console.log(res.detail.access_token);
                    await getPortfolioInfo(portfolioId, res.detail.access_token);
                }
            },
            refetchOnWindowFocus: false,
        },
    );
};

const putPortfolio = (portfolioId: string, data: IPOrtfolioPutInfo | null, accessToken: string | null) => {
    return putFetcher(`/portfolio/${portfolioId}/`, data, { Authorization: bearerString(accessToken) });
};

const usePutPortfolio = () => {
    const accessToken = useRecoilValue(accessTokenAtom);
    const navigate = useNavigate();
    const refreshMutation = useRefresh();

    return useMutation((data: IPOrtfolioPutInfo) => putPortfolio(data.id, data, accessToken), {
        onSuccess: () => {
            alert('제출완료');
            navigate('/auth/portfolios');
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError: async (error: any) => {
            if (error.response?.status === 401) {
                const res = await refreshMutation.mutateAsync();
                console.log(res.detail.access_token);
            }
        },
    });
};

const getPortfolioHistory = (accessToken: string | null, portfolio_id: string | null) => {
    return getFetcher(`/portfolio/${portfolio_id}/history`, { Authorization: bearerString(accessToken) });
};

const usePortfolioHistory = (portfolioId: string) => {
    const accessToken = useRecoilValue(accessTokenAtom);
    const refreshMutation = useRefresh();
    return useQuery<SPortfolioHistory>(['portfolioHistory', accessToken, portfolioId], () => getPortfolioHistory(accessToken, portfolioId), {
        retry: 0,
        onSuccess: (data) => {
            // console.log('############# history ###########');
            // console.log(data);
            return data;
        },
        onError: async (error: any) => {
            if (error.response?.status === 401) {
                const res = await refreshMutation.mutateAsync();
                console.log(res.detail.access_token);
                await getPortfolioList(res.detail.access_token);
            }
        },
        retryDelay: 1000,
        refetchOnWindowFocus: false,
    });
};

export { usePortfolioFormSubmit, usePortfolioHistory, usePortfolioInfo, usePortfolioList, usePutPortfolio };
