import styled from 'styled-components';
import { BaseTextStyle } from '../../atoms/Text/Text';
export const AnalysisPageContainer = styled.div`
    width: 100%;
`;

export const AnalysisFormContainer = styled.div`
    width: 100%;
    padding-top: 8%;
    padding-bottom: 12%;

    color: #474747;
`;

export const AnalysisForm = styled.form`
    margin-top: 60px;
    display: flex;
    flex-direction: column;
`;

export const Title = styled(BaseTextStyle)`
    font-size: 3rem;
    margin-bottom: 32px;
`;

export const SubTitle = styled(BaseTextStyle)`
    font-size: 2rem;
`;

export const Question = styled.div`
    margin-bottom: 104px;
`;

export const QuestionTitle = styled(BaseTextStyle)`
    font-size: 1.5rem;
    color: #474747;
`;

export const QuestionSubTitle = styled(BaseTextStyle)`
    font-size: 1.3rem;
    color: #a7a7a7;
    margin-top: 9px;
`;

export const QuestionContent = styled.div`
    margin-top: 35px;
    margin-left: 13px;
`;

export const QuestionInputs = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 16px;
`;

export const Labels = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const MainLabel = styled(BaseTextStyle)`
    font-size: 2rem;
    color: #474747;
`;

export const SubLabel = styled(BaseTextStyle)`
    font-size: 1.75rem;
    color: #979797;
    font-weiht: 300;
`;

export const AnalysisButton = styled.button`
    width: 400px;
    height: 60px;
    outline: 0;
    border: 0;
    padding: 18px 12px 17px;
    background-color: #474747;
    color: #ffffff;
    border-radius: 5px;
    margin: 0 auto;

    @media (max-width: 480px) {
        width: 100%;
        height: auto;
    }
`;
