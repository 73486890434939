import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useAuthCheck } from '../../../hooks/useAuth';
import { accessTokenAtom } from '../../../recoil/accesToken';
import Footer from '../../molecules/Footer';
import Navigation from '../../molecules/Navigation';
import { navLists } from '../BasicLayout/BasicLayout';
import { InnerStyle, OuterStyle } from '../BasicLayout/style';

const NAuthLayout = () => {
    const accessToken = useRecoilValue(accessTokenAtom);
    const authMutation = useAuthCheck();
    const navigate = useNavigate();
    useEffect(() => {
        if (!accessToken) authMutation.mutate();
    }, []);
    useEffect(() => {
        if (authMutation.isSuccess) navigate('/auth/portfolios', { replace: true });
    }, [authMutation]);
    return (
        <>
            <Navigation navList={navLists} />
            <OuterStyle>
                <InnerStyle>
                    <Outlet />
                </InnerStyle>
            </OuterStyle>
            <Footer />
        </>
    );
};

export default NAuthLayout;
