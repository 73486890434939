import { SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useLogin } from '../../../hooks/useAuth';
import { ILoginUserForm } from '../../../types/User';
import { AnalysisTypeStyle } from '../../atoms/Text/Text';
import SubJumbo from '../../molecules/SubJumbo';
import { InputStyle, LoginButton, LoginForm, LoginFormContainer, LoginFormHeader, LoginPageContainer, LoginSubTextField } from './style';
const Login = () => {
    const {
        register,
        handleSubmit,
        watch,
        // formState: { errors },
    } = useForm<ILoginUserForm>();
    const loginMutation = useLogin();
    const onSubmitHandler: SubmitHandler<ILoginUserForm> = (data) => {
        loginMutation.mutate(data);
    };
    const emailWatch = watch('email');
    const passwordWatch = watch('password');

    return (
        <LoginPageContainer>
            <SubJumbo subtitle={['', '']} title="로그인" />
            <LoginFormContainer className="layout-padding">
                <LoginFormHeader>
                    <AnalysisTypeStyle>
                        당신의 금융성향에 맞는
                        <br />
                        포트폴리오를 만들어보세요!
                    </AnalysisTypeStyle>
                </LoginFormHeader>

                <LoginForm onSubmit={handleSubmit(onSubmitHandler)} className="layout-padding">
                    <InputStyle placeholder="abc@email.com" type="email" {...register('email', { required: true })} />
                    <InputStyle placeholder="비밀번호를 입력해주세요" type="password" {...register('password', { required: true })} />

                    <LoginButton disabled={Boolean(!emailWatch || !passwordWatch) || loginMutation.isLoading} flag={Boolean(emailWatch && passwordWatch) && !loginMutation.isLoading}>
                        로그인
                    </LoginButton>
                    <LoginSubTextField>
                        {/* <Link to="/">이메일 찾기</Link> | <Link to="/">비밀번호 변경</Link> */}
                    </LoginSubTextField>
                    <LoginSubTextField>
                        <span style={{ color: '#aaa' }}>회원 가입이 아직 안되어있다면?</span>{' '}
                        <Link to="/signup" style={{ textDecoration: 'underline' }}>
                            회원가입
                        </Link>
                    </LoginSubTextField>
                </LoginForm>
            </LoginFormContainer>
        </LoginPageContainer>
    );
};

export default Login;
