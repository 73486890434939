import { ButtonBox, Container, DescriptionContainer, TableButton, TableCell, TableContainer, TableHeader, TableRow, TableSubtitle, TableTitle } from './style';

interface ITableProps {
    columns: string[];
    data: string[][];
    title?: string;
    subtitle?: string;
    colspan?: number;
    selectedDateIndex?: number;
    setSelectedDateIndex?: React.Dispatch<React.SetStateAction<number>>;
    standardDates?: string[];
}

const Table = ({ columns, data, title, subtitle, colspan, selectedDateIndex, standardDates, setSelectedDateIndex }: ITableProps) => {
    if (selectedDateIndex == undefined || standardDates == undefined || setSelectedDateIndex == undefined)
        return (
            <Container>
                <DescriptionContainer>
                    <TableTitle>{title}</TableTitle>
                    <TableSubtitle>{subtitle}</TableSubtitle>
                </DescriptionContainer>
                <TableContainer>
                    <colgroup>
                        {columns.map((_, index) => (
                            <col key={index}  />
                        ))}
                    </colgroup>
                    <TableHeader>
                        {columns.map((column, columnIndex) => (
                            <TableCell key={columnIndex} colSpan={colspan}>
                                {column}
                            </TableCell>
                        ))}
                    </TableHeader>
                    {data.map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                            {row.map((cell, cellIndex) => (
                                <TableCell key={cellIndex}>{cell}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableContainer>
            </Container>
        );
    else {
        const ltHandler = () => {
            if (selectedDateIndex === 0 || setSelectedDateIndex === undefined) return;
            else {
                setSelectedDateIndex(selectedDateIndex - 1);
            }
        };
        const gtHandler = () => {
            if (selectedDateIndex === standardDates.length - 2 || setSelectedDateIndex === undefined) return;
            else {
                setSelectedDateIndex(selectedDateIndex + 1);
            }
        };
        return (
            <Container>
                <DescriptionContainer>
                    <TableTitle>{title}</TableTitle>
                    <ButtonBox>
                        <TableButton onClick={ltHandler}>&lt;</TableButton>
                        <TableSubtitle>{standardDates[selectedDateIndex]}</TableSubtitle>
                        <TableButton onClick={gtHandler}>&gt;</TableButton>
                    </ButtonBox>
                </DescriptionContainer>
                <TableContainer>
                    <colgroup>
                        {columns.map((_, index) => (
                            <col key={index} style={{ width: '25%' }} />
                        ))}
                    </colgroup>
                    <TableHeader>
                        {columns.map((column, columnIndex) => (
                            <TableCell key={columnIndex} colSpan={colspan}>
                                {column}
                            </TableCell>
                        ))}
                    </TableHeader>
                    {data.map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                            {row.map((cell, cellIndex) => (
                                <TableCell key={cellIndex}>{cell}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableContainer>
            </Container>
        );
    }
};

export default Table;
