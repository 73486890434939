import styled from 'styled-components';
export const OuterStyle = styled.div`
    position: relative;
    width: cal(100% + 60px);
    top: 0px;

    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const InnerStyle = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    min-height: calc(100vh - 350px);

    @media (max-width: 767px) {
    min-height: calc(100vh - 300px);
    }

    // max-width: 1920px;
`;
