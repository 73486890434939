const formattedRatio = (ratio: number) => {
    const fixedRatio = (ratio * 100).toFixed(2) + ' %';
    return fixedRatio;
};

const formattedModel = (model: string) => {
    if (model === 'pess') return 'SAFE';
    else return 'TOTAL';
};

export { formattedModel, formattedRatio };
