import { MainStyle, MainSubStyle } from '../../atoms/Text/Text';
import { MainJumboContainer, MainJumboDescription } from './style';

const MainJumbo = () => {
    return (
        <MainJumboContainer className="layout-padding">
            <MainJumboDescription>
                <MainStyle fontWeight="800">대담, 하지만 안정적인</MainStyle>
                <MainSubStyle fontWeight="400">새로운 경험의 금융투자 서비스</MainSubStyle>
            </MainJumboDescription>
        </MainJumboContainer>
    );
};

export default MainJumbo;
