import styled from 'styled-components';

interface TextProps {
    fontColor?: string;
    fontWeight?: string;
    lineHeight?: string;
    textAlign?: string;
}

export const BaseTextStyle = styled.p<TextProps>`
    font-family: 'SHSN';
    color: ${(props) => props.fontColor || 'inherit'};
    font-weight: ${(props) => props.fontWeight || 400};
    line-height: ${(props) => props.lineHeight || 'inherit'};
    text-align: ${(props) => props.textAlign || 'inherit'};
`;

export const MainStyle = styled(BaseTextStyle)`
    font-size: 6rem;
    @media (max-width: 767px) {
        font-size:5rem;
    }
`;

export const Main75Style = styled(BaseTextStyle)`
    font-size: 7.5rem;
`;

export const MainSubStyle = styled(BaseTextStyle)`
    font-size: 5rem;
    @media (max-width: 767px) {
        font-size:3.5rem;
    }
`;

export const Main30Style = styled(BaseTextStyle)`
    font-size: 3.5rem;
`;

export const MainImgTitleStyle = styled(BaseTextStyle)`
    font-size: 3rem;
`;

export const TechTitleStyle = styled(BaseTextStyle)`
    font-size: 4rem;
`;

export const AnalysisTypeStyle = styled(BaseTextStyle)`
    font-size: 3rem;
`;

export const AboutDescStyle = styled(BaseTextStyle)`
    font-size: 2.2rem;
    word-break: auto-phrase;
`;

export const MainImgDetailStyle = styled(BaseTextStyle)`
    font-size: 1.8rem;
`;

export const InputTitleStyle = styled(BaseTextStyle)`
    font-size: 2rem;
`;

export const TitleStyle = styled(BaseTextStyle)`
    font-size: 16px;
`;

export const SubJumboSubTitleStyle = styled(BaseTextStyle)`
    font-size: 20px;
    @media screen and (max-width: 767px) {
        font-size: 14px;
    }
`;

export const SubTitleStyle = styled(BaseTextStyle)`
    font-size: 14px;
`;

export const DescriptionStyle = styled(BaseTextStyle)`
    font-size: 12px;
`;

export const DefaultStyle = styled(BaseTextStyle)`
    font-size: 16px;
    @media (max-width: 767px) {
        font-size: 14px;
    }
`;
