import styled, {css} from 'styled-components';

interface FooterContainerProps {
    footerHeight: number;
}

export const FooterContainer = styled.footer<FooterContainerProps>`
    height: 350px;
    padding: 0px 15%;
    background: #f2f2f2;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

    @media (max-width: 767px) {
        height: 300px;
        padding: 0px 5%;
    }
    ${(props) => {
        return css`
            margin-bottom: ${props.footerHeight}px
        `;
    }}
`;

export const LogoContainer = styled.div`
    width: 150px;
    @media (max-width: 767px) {
        width: 100px;
    }
`;

export const FooterHeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 10px 0px;
    @media screen and (max-width: 767px) {
        flex-direction: column;
        gap: 8px;
    }
`;

export const TermsPrivacyInstaWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    p {
        padding-right: 50px;
    }
`;

export const FooterDescriptionWrapper = styled.div`
    color: #aaaaaa;
    p {
        margin: 4px;
    }
`;

export const CellSpanStyle = styled.span`
    display: inline-block;
    min-width: 120px;
`;