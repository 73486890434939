import styled from 'styled-components';
import SubJumboImg from '../../../assets/images/SubJumboImg.png';

export const SubJumboContainer = styled.div`
    width: 100%;
    height: 300px;

    background-image: url(${SubJumboImg});
    background-position: center bottom;
    background-size: cover;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 767px) {
        flex-direction: column;
        height: 150px;
        justify-content: end;
        padding-bottom: 20px;
    }
`;

export const SubJumboDescription = styled.div`
    width: 100%;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 5px;
`;
