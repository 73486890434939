import styled from 'styled-components';

export const PortfolioContainer = styled.div`
    width: 100%;
`;

export const PortfolioTendencyConatiner = styled.div`
    width: 100%;
    padding-bottom: 100px;
`;

export const PropensityTitle = styled.div`
    font-size: 3.5rem;
    font-weight: 500;
    margin-top: 100px;
    margin-bottom: 100px;
`;

export const PortfolioTableContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

export const PortfolioTableTitle = styled.div`
    font-size: 2.4rem;
    font-weight: 700;
    color: #303030;
    margin-bottom: 30px;
`;

export const CustomTableStyle = styled.table`
    text-align: center;
    border-collapse: collapse; /* 테두리 겹치도록 설정 */
    border-spacing: 0; /* 간격 제거 */
`;

export const FormContainer = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 50px;
`;

export const THead = styled.thead``;

export const TBody = styled.tbody``;

export const Tr = styled.tr``;

export const Td = styled.td<{ disabled?: boolean }>`
    color: ${(props) => (props.disabled ? '#BCBCBC' : '#303030')};
    font-size: 1.6rem;
    padding: 10px;
`;

export const ModelContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 100px;
    padding-bottom: 100px;
`;

export const RadioWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

export const ResultButton = styled.button<{ color?: 'primary' | 'secondary' }>`
    height: 60px;
    padding: 18px 20px 17px;
    flex: 1;
    max-width: 280px;
    outline: 0;
    border: 0;
    border-radius: 5px;
    color: #fff;
    font-size: 2rem;
    background-color: ${(props) => (props.color === 'primary' ? '#0465D7' : '#474747')};
`;
